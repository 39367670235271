<template>
  <app-dialog
    v-model="showCommentsDialog"
    :transition="false"
    :value.sync="showCommentsDialog"
    content-class="sourcery__dialog"
    origin="right"
    overlay-color="black"
    overlay-opacity="0.75">
    <v-card
      data-test="row_comments_dialog">
      <v-card-title v-if="$vuetify.breakpoint.mobile">
        Product Details
        <span
          class="sourcery__icon-wrapper black"
          @click="clearCommentsModalTrigger">
          <v-icon
            color="white"
            size="15">
            mdi-close
          </v-icon>
        </span>
      </v-card-title>
      <v-card-text class="card-layout pa-0">
        <!-- comments section -->
        <v-col
          v-if="!shared && !isSpecSheetModalVariant && showCommentsDialog"
          class="px-11 pb-11 pt-9 overflow-y-auto h-100"
          cols="12"
          lg="7">
          <RowCommentsCard
            :show-comments-modal="showCommentsDialog"
            expanded-modal
            :mode="mode"
            :type="null"
            :dialog-for-comments="dialog"
            :row-id="rowId"
            use-extra-toggle
            :extra-toggle-items="commentsToggleItems">
            <template
              #toggleComponent="{ type }">
              <div style="max-height: 80vh">
                <RowDataList
                  v-if="isItemExist"
                  hide-switcher
                  :filter-cols="getColsToFilter(type)"
                  :headers="tipsAndNotesHeaders"
                  :cell-customizations="tipsAndNotesCustomization(type)"
                  :disabled-edit-mode="disabledEditMode"
                  :item="item"
                  :search-text="searchText"
                  @saveFileProcess="saveFileProcess"
                  v-on="$listeners" />
              </div>
            </template>
          </RowCommentsCard>
        </v-col>

        <!-- attachment section -->
        <v-col
          v-if="isSpecSheetModalVariant"
          cols="12"
          lg="7"
          class="row-comment d-flex flex-column px-0">
          <v-icon
            :size="20"
            color="black"
            class="rounded-pill pointer px-1 ml-auto"
            @click="changeProductModalVariant(TYPE_READONLY);">
            mdi-close-circle
          </v-icon>
          <embed
            v-if="getProductModalSpecSheetUrl"
            class="flex-grow-1"
            :src="getProductModalSpecSheetUrl"
            type="application/pdf">
          <div v-else>
            Some error occured!
          </div>
        </v-col>

        <!-- product info -->
        <v-col class="product-col pa-0">
          <!-- views dropdown/search field -->
          <div class="lightGrey d-flex align-center justify-space-between">
            <div
              v-if="!collapsedBP"
              class="white pl-6 pr-2 d-flex align-center"
              style="flex-basis: 12rem; height: 100%;">
              <ProjectDetailsViews minimal-version />
            </div>
            <v-text-field
              v-model="searchText"
              placeholder="Search by field name..."
              autocomplete="off"
              color="black"
              class="ma-0 pa-4 flex-grow-1 bg-lightGrey sourcery__condensed-form search-field"
              clearable
              dense
              flat
              hide-details
              solo />
          </div>
          <RowDataList
            v-if="isItemExist"
            :disabled-edit-mode="disabledEditMode"
            :item="item"
            :search-text="searchText"
            @saveFileProcess="saveFileProcess"
            v-on="$listeners" />
        </v-col>
      </v-card-text>
    </v-card>
    <!-- floating btn -->
    <v-btn
      class="white sourcery__dialog-close"
      fab
      large
      @click="clearCommentsModalTrigger">
      <img src="@/assets/icons/chevron-right-icon.svg">
    </v-btn>
  </app-dialog>
</template>
<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';

import { TYPE_READONLY } from '@/constants';
import productHeaders from '@/constants/productHeaders';

import CustomScrollLayout from '@/mixins/CustomScrollLayout';
export default {
  name: 'ProjectDetailsRowExpandedView',
  components: {
    RowCommentsCard: () => import('@/components/ProjectDetails/ProjectDetailsRowCommentsModal/RowCommentsCard'),
    RowDataList: () => import('./RowDataList'),
    ProjectDetailsViews: () => import('@/components/ProjectDetails/ProjectDetailsViews'),
  },
  mixins: [CustomScrollLayout],
  props: {
    rowId: {
      type: String,
      default: null,
    },
    item: {
      type: Object,
      default: () => ({
      }),
    },
    mode: {
      type: String,
      default: null,
    },
    disabledEditMode: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    TYPE_READONLY,
    dialog: false,
    dialogForComments: false,
    isLoadingImages: false,
    searchText: '',
    commentsToggleItems: [
      {
        id: productHeaders.PRODUCT_NOTES,
        label: 'Product Notes',
      },
      {
        id: productHeaders.TIPS_AND_TRICKS,
        label: 'Tips and Tricks',
      },
    ],
  }),
  computed: {
    ...mapState('ProjectDetailsTableSchedule', ['showCommentsModal']),
    ...mapGetters('Collections', ['getProductModalSpecSheetUrl', 'isSpecSheetModalVariant']),
    ...mapGetters('ProjectDetailsTableSchedule', ['defaultTableHeaders']),
    showCommentsDialog: {
      get() {
        return this.showCommentsModal;
      },
      set() {
        this.clearCommentsModalTrigger();
      },
    },
    isItemExist() {
      return Object.keys(this.item).length;
    },
    typeCellValue() {
      if (!this.isItemExist) {
        return '';
      }
      const typeValue = this.item[productHeaders.TYPE_DESIGNATION];
      return typeValue === '\b' ? '' : typeValue;
    },
    shared() {
      return this.$route.path.includes('/shared-project');
    },
    collapsedBP() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    tipsAndNotesHeaders() {
      const targetHeaders = [
        productHeaders.TIPS_AND_TRICKS,
        productHeaders.PRODUCT_NOTES,
        productHeaders.PROJECT_NOTES,
      ];
      return this.defaultTableHeaders?.filter(header => targetHeaders.includes(header.value));
    },
  },
  watch: {
    showCommentsDialog(val) {
      if (val) {
        return;
      }
      this.clearRowIdQuery();
    },
  },
  methods: {
    ...mapActions('ProjectDetailsTableSchedule', ['showCommentsModalTrigger', 'clearCommentsModalTrigger']),
    ...mapMutations('Collections', ['changeProductModalVariant']),
    clearRowIdQuery() {
      const { query } = this.$route;
      this.$router.replace({
        query: {
          ...query,
          rowId: undefined,
        },
      }).catch(() => {
      });
    },
    saveFileProcess(e = false) {
      this.isLoadingImages = e;
    },
    getColsToFilter(type) {
      const cols = [type];
      if (type === productHeaders.PRODUCT_NOTES) {
        cols.push(productHeaders.PROJECT_NOTES);
      }
      return cols;
    },
    tipsAndNotesCustomization(type) {
      return {
        textArea: {
          rowNumber: type === productHeaders.TIPS_AND_TRICKS ? 28 : 13,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.card-layout {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: stretch;
  align-self: stretch;
  overflow: hidden;
  flex-wrap: wrap-reverse;
}
.product-col {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-left: 1px solid var(--v-darkGrey-base);

  .search-field {
    ::v-deep input {
      text-align: center;
      &::placeholder {
        color: var(--v-darkGrey-base) ;
      }
    }
  }
}
::v-deep.bg-lightGrey.v-text-field {
  .v-input__slot {
    background-color: var(--v-lightGrey-base);
  }
}
</style>