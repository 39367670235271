<template>
  <fragment>
    <CollectionsCreateProductDialog
      :local-product="product"
      :is-allow-comparison="false"
      :status-dialog.sync="statusDialogProduct"
      :is-status-dialog-from-state="false" />
    <TableLayout
      v-show="isShowTable"
      ref="v-data-table--schedule"
      v-click-outside="onClickOutside"
      :custom-id="customId"
      :class="[
        freezeImage,
        imageOverlay,
        `v-data-table--schedule__${selectedViewCriteria}`,
        sharedProjectContainer,
        showActualWidth && 'v-data-table--schedule__no-overflow',
        'pl-6'
      ]"
      :headers="sor.wrap(tableHeaders)"
      :items="sor.wrap(dataWithItemClass, 'scheduleData')"
      @hook:mounted="onTableMountedHook">
      <template #thead="{ headers }">
        <TableHeader
          :project-id="projectId"
          :headers="headers"
          :disabled-edit-mode="disabledEditMode"
          :table-height="tableHeight"
          @onDragEnd="$emit('onDragEnd', $event)"
          @add-table-class="addTableClass"
          @remove-table-class="removeTableClass" />
      </template>
      <template #cellItem="{ header, index, item }">
        <TableCellBlock
          v-if="isVisibleCell(item, header, index)"
          :ref="getRefOfCell(item.id, header.value)"
          :header="header"
          :index="index"
          :item="item"
          :table-id="scheduleId"
          :disabled-edit-mode="disabledEditMode"
          :rendered-draggable-index="renderedDraggableIndex"
          :show-project-detail-actions="!isHiddenProjectDetailActions && !!item.order"
          :data="data"
          :transform-images-to-data="transformImagesToData"
          :is-unsuccessful-payment="isUnsuccessfulPayment"
          :bordered-cell="borderedCell({
            currentRowId: item.id,
            columnName: header.value,
            columnType: header.column.type
          })"
          @setMenuItem="setMenuItem"
          @setMultipleShiftCells="setMultipleShiftCells"
          @setMultipleCtrlCells="setMultipleCtrlCells"
          @updateCell="updateCell"
          @copyContent="(isSelectedCell) => copyContent({
            selected: isSelectedCell,
            columnType: header.column.type,
            columnName: header.value,
            rowId: item.id })"
          @pasteContent="pasteContent({
            columnType: header.column.type,
            oldVal: item[header.value],
            columnName: header.value})"
          @showExpandedDialog="showExpandedDialog(item)" />
      </template>
    </TableLayout>

    <!-- actions -->
    <TableActions
      :show-activator="false"
      :item.sync="selectedItem"
      :position-x="positionX"
      :position-y="positionY"
      :value.sync="showActionsCard"
      @openProductDialog="openProductDialog" />
  </fragment>
</template>

<script>
import {
  mapState, mapMutations, mapActions, mapGetters,
} from 'vuex';
import { SelectiveObjectReuse } from 'selective-object-reuse';

import productHeaders from '@/constants/productHeaders';

import CollectionsCreateProductDialog from '@/components/Collections/CollectionsCreateProductDialog';
import TableActions from '@/components/ProjectDetails/ProjectDetailsTableSchedule/TableActions';
import TableCellBlock from '@/components/ProjectDetails/ProjectDetailsTableSchedule/TableCellBlock';
import TableHeader from '@/components/ProjectDetails/ProjectDetailsTableSchedule/TableHeader';
import TableLayout from '@/components/ProjectDetails/ProjectDetailsTableSchedule/TableLayout';

import AppActionDotsMenu from '@/mixins/AppActionDotsMenu';
import BorderedCell from '@/mixins/BorderedCell';
import draggableMixin from '@/mixins/draggableMixin';
import resizableMixin from '@/mixins/ResizableMixin';
import SetClassForLastUndoAction from '@/mixins/SetClassForLastUndoAction';
import setMultipleScheduleCells from '@/mixins/setMultipleScheduleCells';
import tableKeyboardController from '@/mixins/tableKeyboardController';
import virtualScrollTable from '@/mixins/virtualScrollTable';

import {
  hasEditorAccess, waitTimeout,
} from '@/utils';

import 'vue-draggable-resizable/dist/VueDraggableResizable.css';

export default {
  name: 'ProjectsDetailsTableSchedule',
  components: {
    CollectionsCreateProductDialog,
    TableActions,
    TableCellBlock,
    TableHeader,
    TableLayout,
  },
  mixins: [
    AppActionDotsMenu,
    BorderedCell,
    draggableMixin,
    resizableMixin,
    SetClassForLastUndoAction,
    setMultipleScheduleCells,
    tableKeyboardController,
    virtualScrollTable,
  ],
  props: {
    hideAddingNewRow: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    goToRowComments: {
      type: Boolean,
      default: false,
    },
    isFreeWorkspace: {
      type: Boolean,
      default: () => false,
    },
    customId: {
      type: String,
      default: '',
    },
    isNeedShowAllCells: {
      type: Boolean,
      default: false,
    },
    sharedLink: {
      type: Boolean,
      default: false,
    },
    disabledEditMode: {
      type: Boolean,
      default: false,
    },
    isShowTable: {
      type: Boolean,
      default: false,
    },
    isDisableKeyboard: {
      type: Boolean,
      default: false,
    },
    // For image cache requires transforming to data.
    transformImagesToData: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    product: {
    },
    statusDialogProduct: false,
    privateMode: '',
    productHeaders,
    sor: new SelectiveObjectReuse(),
  }),
  computed: {
    ...mapState(['role', 'imagePreviewOverlay']),
    ...mapState('ProjectDetails', ['detailProjectData']),
    ...mapState('ProjectDetailsTableSchedule', [
      'scheduleId',
      'hoveredColumn',
    ]),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters({
      selectedViewCriteria: 'ScheduleViews/selectedViewCriteria',
      isVersionMode: 'ProjectVersions/isVersionMode',
      isReadOnlyModalVariant: 'Collections/isReadOnlyModalVariant',
      canDragAndDropColumns: 'UserRoles/canDragAndDropColumnsInSchedule',
      userId: 'userId',
      tableHeadersWidth: 'ProjectDetailsTableSchedule/tableHeadersWidth',
      isCellSearchHighlighting: 'SearchProjectCells/isCellSearchHighlighting',
      isTableFocused: 'ProjectDetailsTableSchedule/isTableFocused',
      isResultCompare: 'ScheduleCompare/isResultCompare',
      lastUndoAction: 'UndoActions/lastUndoAction',
      tableHeaders: 'ScheduleViews/tableHeaders',
    }),
    ...mapState('ScheduleViews', ['selectedViewId', 'views']),
    ...mapGetters('ScheduleViews', ['sortingData', 'isSortingTop', 'isSortingBottom', 'selectedViewTag', 'selectedView', 'tableHeaders']),
    isHiddenProjectDetailActions() {
      const { isVersionMode, userId } = this;
      return (!userId || isVersionMode);
    },
    dataWithAllowShowing() {
      const { dataWithItemClass = [] } = this;
      return dataWithItemClass.reduce((newDataArr, rowItem, rowIndex) => {
        const cellsToShowing = this.tableHeaders.reduce((result, option, cellIndex) => {
          const isShowCell = this.setCellShowing({
            cellIndex,
            indexOfRow: rowIndex,
            showDefaultCells: rowItem.status === 'hidden' && !this.hasEditorAccess,
          });
          return {
            ...result,
            [option.value]: isShowCell,
          };
        }, {
        });
        return [...newDataArr, {
          ...rowItem,
          cellsToShowing,
        }];
      }, []);
    },
    dataWithItemClass() {
      const { data = [] } = this;
      return data.reduce((result, option) => {
        let rowClasses = [];
        const { getClassForLastUndoAction } = this;
        const { status, order, id } = option ?? {
        };
        if (status === 'hidden') {
          rowClasses = !this.hasEditorAccess ? ['merged-tr'] : ['hidden-tr'];
        } else if (!order && !id) {
          rowClasses = ['modified-warning-tr'];
        }
        if (this.lastUndoAction) {
          rowClasses = [...rowClasses, getClassForLastUndoAction({
            rowId: id,
          })];
        }
        return [
          ...result,
          {
            ...option,
            rowClasses,
          },
        ];
      }, []);
    },
    showActualWidth() {
      return this.tableHeadersWidth <= (window.innerWidth - 93);
    },
    getStylesForWrapper() {
      const { showActualWidth } = this;
      return {
        width: showActualWidth ? `${this.tableHeadersWidth}px` : '100%',
      };
    },
    hasEditorAccess() {
      return hasEditorAccess(this.role);
    },
    freezeImage() {
      const { tableHeaders } = this;
      return tableHeaders[2]?.value === 'Image' && this.initialArrLength ? 'v-data-table--schedule__freeze-image' : null;
    },
    imageOverlay() {
      const { imagePreviewOverlay } = this;
      return imagePreviewOverlay ? `v-data-table--schedule__image-overlay` : null;
    },
    sharedProjectContainer() {
      return this.sharedLink && 'v-data-table--schedule__share-project';
    },
    projectId() {
      return this.$route.params.id;
    },
    isLoadedTable() {
      return this.tableHeaders.length && this.isShowTable && this.dataWithItemClass.length;
    },
  },
  watch: {
    tableHeadersWidth() {
      this.changeTableWidth();
    },
    async isTableFocused(val) {
      if (this.isDisableKeyboard) {
        return;
      }
      if (val) {
        this.onInitKeyboardController();
        return;
      }
      this.onDestroyKeyboardController();
    },
    scheduleId(val) {
      const { rowId = null } = this.$route.query || {
      };
      if (!rowId || !val) return;
      this.showCommentsModalTrigger({
        rowId: this.$route.query.rowId,
      });
    },
    data: async function () {
      if (!this.$refs['v-data-table--schedule']?.$el) return;
      const { $el } = this.$refs['v-data-table--schedule'] ?? {
      };
      if ($el) {
        const { offsetHeight = '' } = $el.querySelector('table') ?? {
        };
        this.tableHeight = offsetHeight;
      }
    },
    selectedViewId: async function (newVal) {
      if (newVal) {
        // Waiting until dom will be rendered
        await waitTimeout(700);
        const { $el } = this.$refs['v-data-table--schedule'] ?? {
        };
        if ($el) {
          const { offsetHeight = 0 } = $el.querySelector('table') ?? {
          };
          this.tableHeight = offsetHeight;
        }
        const hoveredColumns = this.selectedView.columnOrder.reduce((acc = {
        }, column) => {
          acc[column] = Object.freeze({
            isResized: false,
            active: false,
            clicked: false,
          });
          return acc;
        }, {
        });
        this.initHoveredColumns(hoveredColumns);
      }
    },
    goToRowComments() {
      this.showCommentsModalTrigger({
        rowId: this.$route.query.rowId,
      });
    },
    async isLoadedTable(val) {
      if (val) {
        await this.$nextTick();
        this.setHeightTable();
        if (!this.isDisableKeyboard) {
          this.onSelectFirstCell();
        }
      }
    },
  },
  async mounted() {
    this.setAllItemsForCompare([]);
    this.onResetDisabledTable();
  },
  destroyed() {
    this.onDeleteFocus();
    this.setEditCellEditMode(false);
  },
  methods: {
    ...mapMutations({
      spinner: 'spinner',
      openSnackBar: 'openSnackBar',
      setModalMode: 'ProjectDetailsTableSchedule/setModalMode',
      setAllItemsForCompare: 'setAllItemsForCompare',
      initHoveredColumns: 'ProjectDetailsTableSchedule/initHoveredColumns',
    }),
    ...mapActions('ScheduleViews', ['setViewsSorting']),
    ...mapActions('ProjectDetailsTableSchedule', ['setEditCellEditMode']),
    ...mapActions('ScheduleCompare', ['setAllItemsForCompare']),
    ...mapActions(['handleError']),
    ...mapActions('ProjectDetailsTableSchedule', ['showCommentsModalTrigger']),
    openProductDialog(params) {
      const {
        statusDialogProduct = false, product = {
        },
      } = params || {
      };
      this.product = product;
      this.statusDialogProduct = statusDialogProduct;
    },
    isVisibleCell(item = {
    }, header = {
    }, colIndex = 0) {
      const { id = '', status = '', isCompared } = item ?? {
      };
      if (isCompared) return true;
      const {
        value = '', column = {
        },
      } = header ?? {
      };
      const { type = '' } = column ?? {
      };
      const isCellSearchHighlighting = this.isCellSearchHighlighting({
        rowId: id,
        columnName: value,
      });
      const isShowCalcColumns = this.showCalcColumns({
        cellType: type,
        rowStatus: status,
        rowId: id,
        colIndex,
      });
      return this.isNeedShowAllCells || isCellSearchHighlighting || isShowCalcColumns;
    },
    addTableClass(classToAdd) {
      this.$refs['v-data-table--schedule'].$el.classList.add(classToAdd);
    },
    removeTableClass(classToRemove) {
      this.$refs['v-data-table--schedule'].$el.classList.remove(classToRemove);
    },
    onClickOutside() {
      if (this.isDisableKeyboard) {
        return;
      }
      this.onClickOutsideTable();
    },
    onTableMountedHook() {
      if (this.isNeedShowAllCells) {
        return;
      }
      this.onTableMounted(this.customId);
    },
    async changeTableWidth() {
      await waitTimeout(0);
      if (this.$refs['v-data-table--schedule']?.$el) {
        const table = this.$refs['v-data-table--schedule']?.$el.querySelector('table');
        table.style.width = this.getStylesForWrapper.width;
      }
    },
    showExpandedDialog({ order, id: rowId }) {
      if (!order) {
        return;
      }
      this.showCommentsModalTrigger({
        rowId,
      });
    },
    showCalcColumns({ cellType: type, rowStatus: status, rowId: id, colIndex: index }) {
      if (status === 'hidden' && !this.hasEditorAccess) {
        return ['ORDER_COL', 'TYPE_COL'].includes(type);
      }
      // from mixin
      return this.showColumns({
        rowId: id, colIndex: index,
      });
    },
    setHeightTable() {
      const component = this.$refs['v-data-table--schedule'];
      const elem = component?.$el;
      if (!elem) return;
      this.tableHeight = elem.querySelector('table').offsetHeight;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/assets/styles/__variables.scss';
@import 'src/assets/styles/resizableElement.scss';

$accent-color: rgba(#71d6e0, .2);
$accent-color-header: #DEEFF1;
$grey-color: rgba(#757575, .1);

.v-data-table--highlighting {
  &::v-deep {
    .v-data-table__wrapper tbody {
      //Changes background of hover on row
      & tr:hover {
        // The background of regular cell
        & .table-cell-wrapper {
          &:hover {
            background-color: #fff !important;
          }
        }
        // The background of selected cell because previous selector changes it to active-color
        & .table-cell-wrapper__selected {
          background: #fff !important;
        }
      }
    }
  }
}
::v-deep {
  .table-cell-wrapper__selected--one-overlay,
  .table-cell-wrapper__selected {
    background: #fff !important;
  }
  .schedule-cells .v-skeleton-loader__bone {
    display: flex;
  }
}
.sortable-chosen {
  opacity: .5;
}
.v-data-table-header__deprecated {
  opacity: .5;
}
// Changes color of active row
.table-container__active-cell {
  background: $accent-color;
}
// Changes background of header cell
.table-container__header-cell {
  background: $grey-color;
  &--hover {
    background: $accent-color;
  }
  &--hover-active {
    background: $accent-color-header;
  }
}
// Changes backgorund of cell wich contains order
.table-cell-wrapper__order {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-block: $schedulePaddingTall;

  &.order-cell--Medium {
    padding-block: $schedulePaddingMedium;

    ::v-deep .order-cell {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
}
//Keeps the height of cells and exhons at the same value.
.order-cell--,
.table-cell-- {
  &Extra-tall {
    height: $extraTallHeight + $tableStep !important;
  }
  &Tall {
    height: $tallHeight + $tableStep !important;
  }
  &Medium {
    height: $mediumHeight + $tableStep !important;
  }
  &Small {
    height: $shortHeight + $tableStep !important;
  }
}
</style>
