<template>
  <div class="comments-wrapper">
    <p
      v-if="cellPointer"
      class="comments-wrapper__cell-title small-p">
      <span
        data-test="row_comments_return_to_row_comments"
        class="pointer related-link"
        @click="setCellPointer(undefined)">
        <v-icon small>
          mdi-chevron-left
        </v-icon>
        Return to row comments
      </span>
      <span>
        {{ cellPointer }} comments
      </span>
    </p>

    <p
      v-if="data.nextToken"
      class="medium-p mb-0 mx-8 mb-2 text-center show-more-comments"
      @click="$emit('getRowComments', {
        scanIndexForward: false,
      })">
      <span class="pointer">
        Show Earlier Comments
      </span>
    </p>

    <!-- list of comments -->
    <div
      v-for="(comment, index) in filterComments"
      :id="`commentId_${comment.id}`"
      :key="comment.id"
      ref="commentsRows"
      data-test="project_comment_block"
      class="comments-wrapper-border-items comment"
      :class="{
        'mb-0' : index === data.comments.length -1,
        'comments-wrapper-border-items__edited': editedRowId === comment.id,
        'comments-wrapper-border-items__scrolled-comment': scrolledComment === comment.id,
      }"
      @mouseover="!$route.query.version ? hoverRowId=comment.id : hoverRowId=null"
      @mouseleave="hoverRowId=null">
      <!-- comment title -->
      <div class="comment-title">
        <div class="comment-author">
          <!-- image -->
          <AvatarImage
            :avatar="avatars[getCreatorProp({ comment })]"
            :avatar-key="getCreatorProp({ comment })"
            :color="getCreatorColor({ comment })"
            :size="25" />

          <!-- name + designation -->
          <UserInfo :user-info="comment.creator">
            <template
              v-slot="{
                userProjectTeamRole: teamRole,
                userNameTitleAccordingToRole: userNameTitle
              }">
              <p
                data-test="project_comment_author_name"
                class="ma-0 fs-14 font-fam-poppins">
                {{ userNameTitle }}
                <span v-if="teamRole">
                  {{ teamRole }}
                </span>
              </p>
            </template>
          </UserInfo>
        </div>

        <div class="comment-date">
          <!-- time -->
          <p
            data-test="project_comments_time"
            class="small-p title-in-modal mb-0 d-flex">
            {{ setDateOfComment(comment) }}
            {{ comment.dateModified ? ' (edited)' : '' }}
          </p>

          <!-- edit -->
          <v-icon
            v-if="isShowActionIcon(comment) && comment.commentType !== 'attachment'"
            color="black"
            size="20"
            @click="editRowComment(comment)">
            mdi-pencil
          </v-icon>

          <!-- delete -->
          <v-icon
            v-if="isShowActionIcon(comment)"
            color="red"
            size="20"
            @click="deleteRowComment(comment)">
            mdi-trash-can
          </v-icon>

          <!-- special icon for budget + private + attachment -->
          <!-- <v-icon
            v-if="setCommentIcon(comment) !== ''"
            class="pointer"
            size="20">
            {{ setCommentIcon(comment) }}
          </v-icon> -->
          <v-img
            v-if="setCommentIcon(comment)"
            :src="setCommentIcon(comment)"
            aspect-ratio="1"
            class="pointer"
            width="20" />

          <!-- resolve comment -->
          <v-tooltip
            v-if="!isDisabledComments"
            bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                :color="comment.cellCommentResolved ? 'green' : 'darkGrey'"
                data-test="project_comment_resolve_button"
                size="20"
                v-bind="attrs"
                v-on="on"
                @click="$emit('manageCellCommentResolved', comment)">
                mdi-checkbox-marked-circle
              </v-icon>
            </template>
            <span>{{ setResolveTooltip(comment) }}</span>
          </v-tooltip>

          <!-- collapsed resolved comment -->
          <v-tooltip
            v-if="!isShowResolveAction(comment)"
            bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                medium
                v-bind="attrs"
                class="resolve-reveal-icon"
                color="black"
                v-on="on"
                @click="manageRevealing(comment)">
                {{ setExpandedIcon(comment.id) }}
              </v-icon>
            </template>
            <span>Reveal</span>
          </v-tooltip>
        </div>
      </div>

      <!-- comment body -->
      <div class="d-flex flex-column comment-file">
        <!-- related to field -->
        <p
          v-if="comment.cellPointer"
          class="small-p title-in-modal ma-0">
          Related to <strong>{{ comment.cellPointer }}</strong>
        </p>

        <!-- comment text -->
        <p
          v-if="comment.commentType !== 'attachment'"
          v-safe-html="formattedTextareaValueForDiv(comment) || ''"
          v-linkified
          data-test="project_comment_text"
          class="mb-0"
          :class="{
            'resolved-comment' : comment.cellCommentResolved,
            'resolved-comment__expanded': expandedComments.includes(comment.id),
          }"
          style="overflow-wrap: anywhere" />

        <!-- if attachment -->
        <div
          v-else-if="comment.commentType === 'attachment'"
          target="_blank"
          :class="{
            'resolved-comment' : comment.cellCommentResolved,
            'resolved-comment__expanded': expandedComments.includes(comment.id),
          }"
          class="comment-file-container"
          @click.stop="attachmentClicked(comment.documentId)">
          <!-- image preview -->
          <app-dialog
            ref="dialog"
            v-model="isDialogOpen"
            :value.sync="isDialogOpen"
            content-class="v-dialog__form attachments-documents-wrapper"
            @click:outside="closePreviewDialog">
            <v-overlay class="v-overview__preview-image">
              <v-icon
                class="preview-close"
                @click="closePreviewDialog">
                mdi-close
              </v-icon>
              <img
                :src="attachmentPreviewUrl"
                class="preview-image">
              <v-btn
                class="inverted-btn preview-download"
                color="lightGrey"
                outlined
                @click="downloadFile(comment.documentId)">
                Download
              </v-btn>
            </v-overlay>
          </app-dialog>

          <!-- attachment inside comment layout -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <div
                class="comment-file-container__content pointer"
                v-bind="attrs"
                v-on="on">
                <img
                  v-if="!comment.thumbnailId || !getThumbnail(comment.thumbnailId)"
                  src="@/assets/icons/file-icon.svg">

                <!-- attachment name -->
                <p
                  v-if="!comment.thumbnailId"
                  class="small-p mb-0 overflow-dots"
                  style="max-width: 105px">
                  {{ comment.documentId.split('/')[1] || '' }}
                </p>

                <img
                  v-if="comment.thumbnailId"
                  v-lazy="{
                    src: getThumbnail(comment.thumbnailId),
                    loading: require('@/assets/icons/file-icon.svg'),
                  }"
                  class="img-screen thumbnail-img">

                <!-- download btn -->
                <a
                  :href="`/getFile?fileId=${comment.documentId}&force_update=true`"
                  @click.prevent="downloadFile(comment.documentId)">
                  Download
                </a>
              </div>
            </template>
            <span>
              {{ comment.documentId.split('/')[1] || '' }}
            </span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <p
      v-if="data.nextTokenForUnread && !type"
      class="medium-p mb-0 mt-2 mx-8 text-center show-more-comments"
      @click="$emit('getRowComments', {
        scanIndexForward: true,
      })">
      <span class="pointer">
        Show the Latest Comments
      </span>
    </p>
  </div>
</template>

<script>
import { Storage } from 'aws-amplify';
import {
  mapState,
  mapActions,
  mapMutations,
} from 'vuex';
import linkify from 'vue-linkify';

import LoadingFileSvg from '@/assets/icons/file-icon.svg';
import DollarIcon from '@/assets/icons/dollar-icon.svg';
import LockIcon from '@/assets/icons/lock-icon.svg';
import LinkIcon from '@/assets/icons/link-icon.svg';

import focusOutModal from '@/mixins/FocusOutModal';

import { isImageUrl } from '@/utils/url';

export default {
  name: 'RowComments',
  components: {
    UserInfo: () => import('@/components/App/AppShareModal/AppShareModalUserInfo'),
  },
  directives: {
    linkified: linkify,
  },
  mixins: [focusOutModal],
  props: {
    allowResolveComments: {
      type: Boolean,
      default: false,
    },
    filesSrc: {
      type: Object,
      default: () => {
      },
    },
    editMode: {
      type: Boolean,
      default: true,
    },
    editedRowId: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
      default: () => ({
        comments: [],
        nextToken: null,
      }),
    },
    isDisabledComments: {
      type: Boolean,
      default: false,
    },
    rowId: {
      type: String,
      default: null,
    },
    scrolledComment: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    isDialogOpen: false,
    previewId: '',
    previewUrl: '',
    hoverRowId: null,
    expandedComments: [],
  }),
  computed: {
    ...mapState(['avatars', 'userInfo']),
    ...mapState('Comments', ['hideResolvedComments']),
    ...mapState('ProjectDetailsTableSchedule', ['cellPointer']),
    attachmentPreviewUrl() {
      return this.previewUrl ?? LoadingFileSvg;
    },
    filterComments() {
      if (this.hideResolvedComments) {
        return this.data.comments.filter(el => !el.cellCommentResolved);
      }
      return this.data.comments;
    },
    slant() {
      return this.$store.getters['Comments/slant'](this.rowId);
    },
  },
  watch: {
    isDialogOpen(val) {
      if (val) {
        this.createFocusEvent();
      } else {
        this.removeFocusEvent();
      }
    },
  },
  destroyed() {
    this.expandedComments = [];
    this.$emit('update:filesSrc', {
    });
    this.removeFocusEvent();
  },
  methods: {
    ...mapActions(['getFileFromS3']),
    ...mapMutations('ProjectDetailsTableSchedule', ['setCellPointer']),
    getCreatorColor({ comment }) {
      return this.getCreatorProp({
        comment, prop: 'id',
      })?.substring(0, 6);
    },
    getCreatorProp({ comment, prop = 'picture' }) {
      return comment.creator && comment.creator[prop];
    },
    getThumbnail(id) {
      return this.filesSrc[id];
    },
    isShowActionIcon(comment) {
      if (!this.userInfo) return false;
      const userId = this.userInfo.sub;
      const { creator } = comment;
      const { id: creatorId } = creator;
      if (!creatorId || !userId) return false;
      return userId === creatorId;
    },
    setResolveTooltip(comment) {
      if (!this.editMode) {
        return 'Go to row comments to resolve or unresolve the comment';
      }
      return this.isShowResolveAction(comment) ?
        'Resolve' : 'Re-open';
    },
    setExpandedIcon(id) {
      return this.expandedComments.includes(id) ?
        'mdi-chevron-up' : 'mdi-chevron-down';
    },
    isShowResolveAction({ cellCommentResolved }) {
      return !cellCommentResolved;
    },
    commentsUnread(comment) {
      const {
        type,
        slant,
        userInfo,
        allowResolveComments,
      } = this;
      const {
        id = '',
        creator,
      } = comment || {
      };
      const { email: emailFromComment = '' } = creator || {
      };
      const { email: emailCurrentUser = '' } = userInfo || {
      };
      const { unreadMessageIDs } = slant || [];
      const unreadComment = unreadMessageIDs?.includes(id);
      const useInGeneralChat = allowResolveComments;
      return unreadComment && (!type || useInGeneralChat) && emailCurrentUser !== emailFromComment;
    },
    setDateOfComment(comment) {
      const { dateModified, createdDate } = comment;
      const date = dateModified ? dateModified : createdDate;
      return this.$moment(date).fromNow();
    },
    async downloadFile(key) {
      this.getFileFromS3({
        key,
        forceUpdate: true,
      });
    },
    manageRevealing({ cellCommentResolved, id }) {
      const { expandedComments } = this;
      if (!cellCommentResolved || expandedComments.includes(id)) {
        this.expandedComments = expandedComments.filter(commentId => commentId !== id);
      } else {
        this.expandedComments = [...expandedComments, id];
      }
    },
    deleteRowComment(comment) {
      this.$emit('deleteRowComment', comment);
    },
    editRowComment(comment) {
      this.$emit('editRowComment', comment);
    },
    setCommentIcon({ commentType, privacy }) {
      let icon = '';
      if (privacy === 'private') {
        icon = commentType === 'offer' ? DollarIcon : LockIcon;
      } else if (commentType === 'attachment') {
        icon = LinkIcon;
      }
      return icon;
    },
    formattedTextareaValueForDiv(comment) {
      if (comment.message) {
        let formattedString = comment.message.replace(/\r?\n/g, '<br>');
        comment.mentioned.forEach((user) => {
          const { email = '' } = user || {
          };
          if (user) {
            const getAllIndexes = (arr, val) => {
              const indexes = [];
              let i = -1;
              while ((i = arr.indexOf(val, i + 1)) != -1) {
                indexes.push(i);
              }
              return indexes;
            };
            const indexes = getAllIndexes(formattedString, `@${email}`);
            indexes.forEach((foundIndex, indexOfArr) => {
              let index = foundIndex;
              if (indexOfArr !== 0) {
                index = foundIndex + indexOfArr * 7;
              }
              if (index >= 0) {
                formattedString = `${formattedString.slice(0, index)}<a>${formattedString
                  .slice(index, index + email.length + 1)}</a>${formattedString
                  .slice(index + email.length + 1)}`;
              }
            });
          }
        });
        return formattedString;
      }
      return '';
    },
    async attachmentClicked(docId) {
      if (isImageUrl(docId)) {
        this.openPreviewDialog();
        if (this.previewId != docId) {
          try {
            const res = await Storage.get(docId, {
              level: 'public',
            });
            this.previewUrl = res;
          } catch (err) {
            console.error('Failed to get url for img: ', docId);
            console.error(err);
          }
        }
      } else {
        window.open(`/getFile?fileId=${docId}`, '_blank');
      }
    },
    openPreviewDialog() {
      this.isDialogOpen = true;
    },
    closePreviewDialog() {
      this.isDialogOpen = false;
    },
  },
};
</script>

<style scoped lang="scss">
.comments-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  gap: 20px;
  justify-content: flex-end;

  &__cell-title {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    background: white;
    text-align: center;
  }

  &-border-items {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 0;
    padding: 24px;
    border-radius: 10px 10px 10px 0px;

    &:nth-child(odd) {
      background: var(--v-lightGrey-base);
    }

    &__edited {
      border: 1px solid var(--v-blue-base);
      opacity: 0.8;
    }

    &__scrolled-comment {
      background: #f3f3f9;
    }
  }

  .comment-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    flex-wrap: wrap;
  }

  .comment-author {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .comment-date {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
  }

  .resolved-comment {
    display: none;

    &__expanded {
      display: block;
    }
  }

  .show-more-comments {
    color: var(--v-blue-base);
    &:hover {
      opacity: 0.6;
    }
  }

  .comment-file {
    &-container {
      max-width: 105px;

      &__content {
        display: grid;

        img {
          border-radius: 5px;
        }
      }
    }
  }
}
.v-overlay--active {
  ::v-deep .v-overlay__content {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
  }
}
.preview-close {
  position: fixed;
  top: 24px;
  right: 48px;
  z-index: 1000000;
}
.preview-image {
  display: block;
  margin: 0 auto;
  max-width: 70%;
  max-height: 58vh;
  align-self: center;
  margin-bottom: 80px;
}
.preview-download {
  position: fixed;
  bottom: 24px;
  right: 48px;
  z-index: 1000000;
}
</style>
